import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74')
];

export const server_loads = [0];

export const dictionary = {
		"/(main)": [12,[2]],
		"/auth/login": [~73],
		"/auth/setPassword": [74],
		"/(main)/dashboard": [13,[2]],
		"/(main)/dashboard/list": [15,[2]],
		"/(main)/dashboard/new": [16,[2]],
		"/(main)/dashboard/[dashboardId]/edit": [14,[2]],
		"/(main)/profile": [17,[2]],
		"/(main)/registries": [18,[2]],
		"/(main)/registries/customers": [19,[2]],
		"/(main)/registries/customers/create": [34,[2]],
		"/(main)/registries/customers/[customerId]": [20,[2,3]],
		"/(main)/registries/customers/[customerId]/general": [21,[2,3]],
		"/(main)/registries/customers/[customerId]/pricing": [22,[2,3]],
		"/(main)/registries/customers/[customerId]/pricing/create": [26,[2,3]],
		"/(main)/registries/customers/[customerId]/pricing/[pricingId]": [23,[2,3,4]],
		"/(main)/registries/customers/[customerId]/pricing/[pricingId]/info": [24,[2,3,4]],
		"/(main)/registries/customers/[customerId]/pricing/[pricingId]/update": [25,[2,3,4]],
		"/(main)/registries/customers/[customerId]/revenue": [27,[2,3]],
		"/(main)/registries/customers/[customerId]/services": [28,[2,3]],
		"/(main)/registries/customers/[customerId]/users": [29,[2,3]],
		"/(main)/registries/customers/[customerId]/users/create": [33,[2,3]],
		"/(main)/registries/customers/[customerId]/users/[userId]": [30,[2,3,5]],
		"/(main)/registries/customers/[customerId]/users/[userId]/general": [31,[2,3,5]],
		"/(main)/registries/customers/[customerId]/users/[userId]/permissions": [32,[2,3,5]],
		"/(main)/registries/reportStatuses": [35,[2]],
		"/(main)/registries/users": [36,[2]],
		"/(main)/registries/users/create": [40,[2]],
		"/(main)/registries/users/[userId]": [37,[2,6]],
		"/(main)/registries/users/[userId]/general": [38,[2,6]],
		"/(main)/registries/users/[userId]/permissions": [39,[2,6]],
		"/(main)/reports": [41,[2,7]],
		"/(main)/reports/search": [43,[2,7]],
		"/(main)/reports/[reportId]": [42,[2,7]],
		"/(main)/requests": [44,[2]],
		"/(main)/requests/new": [49,[2]],
		"/(main)/requests/[requestId]": [45,[2,8]],
		"/(main)/requests/[requestId]/renew": [46,[2,8]],
		"/(main)/requests/[requestId]/report": [47,[2,8]],
		"/(main)/requests/[requestId]/request": [48,[2,8]],
		"/(main)/search": [50,[2]],
		"/(main)/settings": [51,[2]],
		"/(main)/settings/colors": [52,[2]],
		"/(main)/settings/developers": [53,[2,9]],
		"/(main)/settings/developers/ateco": [54,[2,9]],
		"/(main)/settings/developers/currencies": [55,[2,9]],
		"/(main)/settings/developers/naics": [56,[2,9]],
		"/(main)/settings/developers/peoNumbers": [57,[2,9]],
		"/(main)/settings/developers/sic": [58,[2,9]],
		"/(main)/settings/industrySector": [59,[2]],
		"/(main)/settings/industrySector/create": [61,[2]],
		"/(main)/settings/industrySector/[industrySectorId]": [60,[2]],
		"/(main)/settings/monitoring": [62,[2]],
		"/(main)/settings/registry": [63,[2,10]],
		"/(main)/settings/registry/general": [64,[2,10]],
		"/(main)/settings/registry/users": [65,[2,10]],
		"/(main)/settings/registry/users/create": [69,[2,10]],
		"/(main)/settings/registry/users/[userId]": [66,[2,10,11]],
		"/(main)/settings/registry/users/[userId]/general": [67,[2,10,11]],
		"/(main)/settings/registry/users/[userId]/permissions": [68,[2,10,11]],
		"/(main)/settings/roles": [70,[2]],
		"/(main)/settings/roles/new": [72,[2]],
		"/(main)/settings/roles/[roleId]": [71,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';